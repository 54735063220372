<template>
  <div :class="['value-prop', { cntr: hide_title !== true }, { 'short-prop': hide_title === true }]">
    <template v-if="hide_title !== true">
      <div class="h2">
        {{ title }}
      </div>
      <Separator :level="5" />
    </template>
    <div :class="['prop-items', 'between', 'flex-inline']">
      <div
        v-for="(prop, index) in valuePropRawState.mappedProps"
        :key="prop.prop_header + index"
        class="prop-item flex-col cntr"
      >
        <LazySvgTemplate v-if="prop.icon && prop.name" :svg-name="prop.name" class="icon" />
        <div class="h5 header">
          {{ prop.prop_header }}
        </div>
        <div class="desc">
          {{ prop.prop_description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  value_props: {
    type: Array as () => any[],
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  // eslint-disable-next-line vue/prop-name-casing
  hide_title: {
    type: Boolean,
    default: false,
  },
});

const valuePropRawState = markRaw({
  mappedProps: [] as any[],
});

// Do not include in top level of setup. Will break build.
onBeforeMount(() => {
  valuePropRawState.mappedProps = (props.value_props || []).map((item: any) => {
    return {
      ...item,
      ...(item?.icon
        ? {
            name: item?.icon,
          }
        : {}),
    };
  });
});
</script>

<style lang="scss">
.value-prop {
  @include local-mixins.tablet_and_mobile {
    margin: 0 1rem;
  }
  .h2 {
    text-align: center;
    margin-bottom: #{local-functions.rem-calc(25)};
  }
}
.prop-items {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  margin-top: #{local-functions.rem-calc(25)};
  @include local-mixins.desktop {
    display: flex;
    max-width: #{local-functions.rem-calc(900)};
    margin-left: auto;
    margin-right: auto;
  }
}
.prop-item {
  text-align: center;
  .icon {
    // TODO - uncomment height when h/w removed from svg
    // height: 40px;
    // width: 40px;
    fill: $color-primary-500;
  }
  .header {
    margin-top: 0.75rem;
  }
  .desc {
    font-size: 0.875rem;
  }
}
.short-prop {
  .prop-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0;
    @include local-mixins.desktop {
      max-width: none;
    }
  }
  .prop-item {
    display: grid;
    grid-template-columns: auto auto;
    text-align: left;
    justify-items: start;
    align-items: flex-start;
  }
  .header {
    line-height: 1em;
    margin: 0;
  }
  .icon {
    grid-row: 1 / span 2;
    margin-right: 0.5rem;
  }
}
</style>
